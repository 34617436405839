<script>
import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      email: "",
      password: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false
    };
  },
  methods: {
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.logIn({
          email: this.email,
          password: this.password
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            // Redirect to the originally requested page, or to the home page
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            );
          })
          .catch(error => {
            this.tryingToLogIn = false;
            this.authError = error ? error : "";
            this.isAuthError = true;
          })
      );
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-5 col-lg-5 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-white">
                <img src="@/assets/images/logo-dark.png"  width="100%" alt class=" px-2 img-fluid" />
          </div>
          <div class="card-body pt-0">
            <div class="p-4">
              <h1 class="text-center"><span class="text-danger">Undian</span> Apps</h1>
              <!-- <h4 class="card-title mb-4 text-center"> Tracking & Order Management System</h4> -->
            </div>

            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{authError}}</b-alert>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-group id="input-group-1" label="Email" label-for="input-1">
                <b-form-input id="input-1" v-model="email" type="text" placeholder="Enter email"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Password" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  placeholder="Enter password"
                ></b-form-input>
              </b-form-group>
              <div class="mt-3">
                <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
              </div>
              <div class="mt-4 text-center">
                <router-link tag="a" to="/forgot-password" class="text-muted">
                   Silahkan Masuk Untuk Memulai Sesi
                </router-link>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
